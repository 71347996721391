import * as React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Consult FGC | Digital Marketing Agency | Website Design & Development
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://consultfgc.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://consultfgc.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>
      <div className="relative bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-20 relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div className="relative">
              <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Privacy and Data Protection
              </h3>
            </div>
            <div className="pt-6">
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Definitions
              </h1>
              <p class="text-gray-500">
                Consult FGC – Consult FGC, LLC. Referred to herein as “Consult FGC”, “we”, “our”,
                or “us”. This includes Consult FGC employees and subcontractors.
              </p>
              <p class="text-gray-500">
                {" "}
                Customer – The individual or legal entity who is contracting for
                services from Consult FGC. This includes your employees or
                subcontractors. Referred to herein as “customer”, “you”, or
                “your”.{" "}
              </p>
              <p class="text-gray-500">
                Processor – As used herein, this refers to Consult FGC acting in the
                role of a data processor, defined as a person or organization
                (in this case Consult FGC) who deals with your (our customers’)
                personal data.{" "}
              </p>
              <p class="text-gray-500">
                Personal Data – Refers to any personally identifiable
                information such as one’s name, address, date of birth, phone
                number, and email address.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Product Data Protection Notice - May 2020
              </h1>
              <p class="text-gray-500">
                We at Consult FGC are committed to protecting our customers’ data. The
                data protection practices set forth in this Product Data
                Protection Notice (the “Product Data Protection Notice”) are for
                technology platforms owned by Consult FGC, LLC. This Product Data
                Protection Notice tells you (as our customer) how Consult FGC uses
                Personal Data collected on our technology platform(s).
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                By using our technology platform(s), you are accepting the
                practices described in this Product Data Protection Notice. If
                you do not agree with the data practices provided in this
                Product Data Protection Notice, you should not use products and
                services provided by Consult FGC. We may make changes to this Product
                Data Protection Notice at our sole discretion at any time. We
                encourage you to periodically review this website Data
                Protection Notice to stay informed about our collection,
                processing, and sharing of your Personal Data. Should you
                continue to use the Software after we make changes to the
                Product Data Protection Notice such use will be deemed to be
                acceptance of those changes.
              </p>
              <br />
              <p class="text-gray-500">
                This Product Data Protection Notice applies only to the extent
                we process Personal Data in the role of a processor on your
                behalf. If you have executed a separate Data Protection
                Agreement with Consult FGC, the terms of that agreement will supersede
                this Product Data Protection Notice.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                What This Notice Covers
              </h1>
              <p class="text-gray-500">
                This Product Data Protection Notice applies to the processing of
                Personal Data collected by us when:{" "}
              </p>
              <p class="text-gray-500">
                • You create an account to use our products and services.
              </p>
              <p class="text-gray-500">
                {" "}
                • You use our products and services, where we act as a processor
                of your Personal Data.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                The Personal Data that we collect directly from you includes
              </h1>
              <p class="text-gray-500">
                • Business Contact information: Includes such things as first
                name, last name, employer, title, city, state, country, phone
                number, IP address, and business email addresses.
              </p>
              <p class="text-gray-500">
                {" "}
                • Automatically collected information: This includes information
                collected via cookies and web beacons, including IP address,
                Advertiser id, browser name, operating system details, domain
                name, date of visit, time of visit, and pages viewed, or other
                similar information.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Protected Health Information, Payment Card Information and other
                Sensitive Information
              </h1>
              <p class="text-gray-500">
                Consult FGC’s services to not require, nor shall we request from you,
                any of the following types of data:
              </p>

              <p class="text-gray-500">
                {" "}
                • Protected health information (“PHI”) governed by the Health
                Insurance Portability and Accountability Act and its
                implementing regulations (“HIPAA”).
              </p>

              <p class="text-gray-500">
                • Any non-public consumer personally identifiable information or
                financial information governed by the Gramm-Leach-Bliley Act
                (“GLBA”) or payment card information covered by the Payment Card
                Industry Data Security Standards (“PCI DSS”).{" "}
              </p>

              <p class="text-gray-500">
                You should never disclose, or allow to be disclosed, PHI,
                information protected by PCI DSS or GLBA, or any other sensitive
                or protected information to Consult FGC. Preventing the disclosure of
                such information to Consult FGC is solely your responsibility. Consult FGC
                is not liable for any such disclosures of your protected
                information to it.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Visitors under the age of 16
              </h1>
              <p class="text-gray-500">
                Our website and technology platforms are not intended for
                persons under the age of 16. Thus, we do not knowingly or
                intentionally gather Personal Data from visitors who are under
                the age of 16. If you are under the age of 16, please do not
                submit your Personal Data via our submission forms. Preventing
                the disclosure of such information to Consult FGC is solely your
                responsibility. Consult FGC is not liable for any disclosures of
                personal information of persons under 16 to it.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How Personal Data is Collected
              </h1>
              <p class="text-gray-500">
                Personal Data is collected by Consult FGC when it is shared by you or
                someone in your organization authorized by you to access and
                otherwise interact with Consult FGC or any Consult FGC technology systems.
                Disclosure of Personal Data to Consult FGC or one of our systems is at
                the discretion of your organization, employees, and
                subcontractors.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                Personal Data may also be requested from you through your
                interaction with our products and services (i.e. technology
                platforms). Consult FGC collects the minimum information necessary to
                provide its products and services to you.{" "}
              </p>
              <br />
              <p class="text-gray-500">
                You are solely responsible for the information you provide to
                Consult FGC.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Cookies and Other Identifiers
              </h1>
              <p class="text-gray-500">
                We use industry standard information-gathering tools, such as
                tools for collecting usage data, cookies, web beacons and
                similar technologies, to automatically collect information that
                may contain Personal Data from your computer or mobile device as
                you navigate our site, use our services, or interact with emails
                we have sent to you.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Cookies, web beacons and other tracking technologies on our
                products and services
              </h1>
              <p class="text-gray-500">
                Consult FGC uses cookies and other tracking technologies, in
                accordance with accepted industry standards, when users interact
                with our products and services. Cookies are small text files
                stored on a user's computer by a website. Each one contains an
                identification number, IP address, and the time and date last
                accessed. Consult FGC does not use cookies contained within our
                products and services for targeted advertising.{" "}
              </p>
              <br />
              <p class="text-gray-500">
                Below are the two types of cookies that are used on Consult FGC’s
                platform for its products and services:{" "}
              </p>

              <p class="text-gray-500">
                • Session-based cookies – These are used only to determine how
                long you remain on the platform. They immediately expire when
                you leave our platform or logout.{" "}
              </p>

              <p class="text-gray-500">
                • Support cookies – These cookies allow us to track onboarding
                times and other metadata in order to provide better service to
                our users.{" "}
              </p>

              <p class="text-gray-500">
                • Location-enabled cookies – These cookies allow us to track
                your location at the time of use of our products.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How We Use Your Personal Data
              </h1>
              <p class="text-gray-500">
                We collect and process your Personal Data, we act as a processor
                of your Personal Data, solely for the legal purposes described
                below:{" "}
              </p>
              <p class="text-gray-500">
                • Where we have entered into a contract for the use of our
                website including any products and services.
              </p>
              <p class="text-gray-500">
                {" "}
                • In order to provide you support for our products and services.
              </p>
              <p class="text-gray-500">
                {" "}
                • For any managed services that we may provide to you from time
                to time.
              </p>
              <p class="text-gray-500">
                Other legitimate interests for processing your Personal Data
                are:{" "}
              </p>
              <p class="text-gray-500">
                • To assess and improve your experience using our Products, such
                as analyzing trends or tracking your usage and interactions with
                our products and services in order to improve your overall
                experience.
              </p>
              <p class="text-gray-500">
                {" "}
                • For security purposes, such as investigations of suspicious
                activity.{" "}
              </p>
              <p class="text-gray-500">
                • For compliance purposes, such as investigating fraud or misuse
                of our Products.
              </p>
              <p class="text-gray-500">
                {" "}
                • For other purposes that arise from time to time.{" "}
              </p>

              <br />
              <p class="text-gray-500">
                Consult FGC processes and discloses Personal Data when cooperating
                with appropriate regulatory and government authorities. When
                Consult FGC processes Personal Data for this purpose, the legal basis
                for processing shall be for compliance with a legal obligation
                to which Consult FGC is subject.{" "}
              </p>
              <p class="text-gray-500">
                You may make changes directly to your Personal Data. Consult FGC does
                not have control over how your organization uses your Personal
                Data for its purposes.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Who Do We Share Personal Data With?
              </h1>
              <p class="text-gray-500">
                We may occasionally use third-party businesses to provide
                products and perform specialized services for data processing.
                When we provide Personal Data to these businesses, they are not
                permitted to use the Personal Data for any reason outside of the
                scope for which we have contracted with them.{" "}
              </p>
              <br />
              <p class="text-gray-500">
                The ways in which we may share your Personal Data include the
                following:{" "}
              </p>
              <p class="text-gray-500">
                • When we use our third-party processors (such as Amazon Web
                Services) in the performance of our services. This is required
                for us to provide our services to you. We execute contracts with
                such third-party businesses to ensure they fulfill their data
                protection obligations as covered herein.
              </p>
              <p class="text-gray-500">
                {" "}
                • With Consult FGC affiliates and other companies that may become part
                of Consult FGC in the future.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                We may also disclose your information to a buyer or other
                successor in the event of a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding. Finally, we may
                also disclose your information for any other purpose when you
                provide the information or with your consent.
              </p>
              <p class="text-gray-500">
                {" "}
                Consult FGC reserves the right to disclose your Personal Data under
                the following conditions: (1) when permitted or required by law;
                (2) when trying to protect against or prevent actual or
                potential fraud or unauthorized transactions; or (3) when
                investigating suspected fraud which has already taken place.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Sale of Personal Data
              </h1>
              <p class="text-gray-500">
                Consult FGC will never sell your Personal Data.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                International Transfers of Personal Data
              </h1>
              <p class="text-gray-500">
                Your Personal Data may be collected, transferred to, and stored
                by us in the United States and by our affiliates in other
                countries where we operate.
              </p>
              <br />
              <p class="text-gray-500">
                Therefore, your Personal Data may be processed outside the
                European Economic Area (EEA), and in countries which are not
                subject to an adequacy decision by the European Commission, and
                which may not provide for the same level of data protection as
                the EEA. In this event, we will make every reasonable effort to
                ensure that the recipient of your Personal Data offers an
                adequate level of protection, such as by entering into an
                agreement to abide by standard contractual clauses for the
                transfer of data as approved by the European Commission (Art. 46
                GDPR), or another mechanism approved by the EU Commission.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Data Security and Retention
              </h1>
              <p class="text-gray-500">
                To ensure security of your Personal Data, we use a variety of
                industry standard and best practice security measures to prevent
                unauthorized access, use of, or disclosure. These security
                measures consist of, but are not limited to, data encryption and
                physical security. Only authorized employees, agents, and
                contractors will have access to this information. We will not
                process Personal Data in a way that is incompatible with the
                purposes for which it was collected.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                On occasion, and at its discretion, Consult FGC may contract with
                third-party entities to perform specialized data processing and
                other services necessary for us to provide you our products and
                services. These third-party entities are not permitted to use
                data outside of the scope for which we contract with them.
                Further, these entities are required to follow the security
                practices outlined herein.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                While Consult FGC will take all reasonable steps to ensure that your
                Personal Data is accurate, complete, current, and otherwise
                reliable for its intended use, customer understands that no
                method of transmission or method of electronic storage over the
                internet is 100% secure. Therefore, while we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                Consult FGC will retain your Personal Data only for the period
                necessary to fulfill the purpose outlined in this Product Data
                Protection Notice unless a longer retention period is required
                by applicable data privacy law.
              </p>
              <br />
              <p class="text-gray-500">
                {" "}
                If your Personal Data has been disclosed to a third party, and
                it has been deemed incorrect by you, Consult FGC will take
                commercially reasonable steps to obtain correction of the
                information.{" "}
              </p>
              <br />
              <p class="text-gray-500">
                If Consult FGC obtains knowledge that one of our service providers or
                employees is in violation of this Product Data Protection
                Notice, Consult FGC will take commercially reasonable steps to prevent
                or stop the unauthorized use or disclosure of your Personal
                Data. Consult FGC takes data privacy seriously. We agree to take
                commercially reasonable measures to ensure the proper handling
                of your Personal Data by our employees and service providers.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Your Rights
              </h1>
              <p class="text-gray-500">
                You have certain rights relating to your Personal Data, subject
                to local data protection laws. Depending on applicable laws and,
                in particular if you are located in the EEA, these rights may
                include:{" "}
              </p>
              <br />
              <p class="text-gray-500">
                • Accessing, correcting, amending, deleting your Personal Data;{" "}
              </p>
              <p class="text-gray-500">
                • Objecting to any processing of your Personal Data carried out
                on the basis of our legitimate interests (right to object).
                Where we process your Personal Data for direct marketing
                purposes or share it with third parties for their own direct
                marketing purposes, you can exercise your right to object at any
                time to such processing without having to provide any specific
                reason for such objection;{" "}
              </p>
              <p class="text-gray-500">
                • Not being subject to a decision based solely on automated
                processing, including profiling, which produces legal effects
                (“Automated Decision-Making”). Automated Decision-Making
                currently does not take place within our products or in our
                services; and
              </p>
              <p class="text-gray-500">
                {" "}
                • To the extent we base the collection, processing and sharing
                of your Personal Data on your consent, withdrawing your consent
                at any time, without affecting the lawfulness of the processing
                based on such consent before its withdrawal.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                How to exercise your rights
              </h1>
              <p class="text-gray-500">
                To exercise your rights, please contact us by email at
                ‘webmaster “at” Consult FGC.com’.
              </p>
              <h1 class="mt-6 mb-2 text-lg leading-7 text-left w-full flex justify-between items-start focus:outline-none font-semibold">
                Contacting Us
              </h1>
              <p class="text-gray-500">
                To exercise your rights regarding your Personal Data, or if you
                have questions regarding this Product Data Protection Notice or
                our data protection practices please send an email to ‘webmaster
                “at” Consult FGC.com’. Alternatively, you may send notice by way of
                mail at the address listed below:{" "}
              </p>
              <br />
              <p class="text-gray-500">Consult FGC, Inc. </p>
              <p class="text-gray-500">
                1659 Achieva Way Dunedin, FL 34698 USA{" "}
              </p>
              <p class="text-gray-500">Attn: Consult FGC</p>
              <br />
              <p class="text-gray-500">
                {" "}
                We are committed to working with you to obtain a fair resolution
                of any complaint or concern about your data. If, however, you
                believe that we have not been able to assist with your complaint
                or concern, and you are located in the EEA, you have the right
                to lodge a complaint with the competent supervisory authority.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
